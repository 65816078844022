import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { useTStream } from "#/hooks/use-t-stream";
import { toast } from "#/lib/toast";
import { cn } from "#/lib/utils";
import { ThemeContext } from "#/providers/theme-provider";
import { DrawerDialogStacked } from "#/ui/drawer-dialog";
import Loader from "#/ui/standard-loader";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { RefreshCwIcon } from "lucide-react";

const buttonVariants = cva(
  "inline-flex cursor-pointer items-center justify-center truncate whitespace-nowrap rounded-xl text-sm font-500 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        ["outline-invert"]:
          "bg-primary border-text-primary text-primary-foreground border-[1px]",
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        "destructive-outline":
          "border border-destructive text-destructive hover:bg-destructive/90",
        outline:
          "border border-primary text-primary hover:bg-accent hover:text-accent-foreground",
        "outline-muted":
          "border border-border text-muted hover:opacity-70 transition",
        "secondary-foreground": "bg-card text-foreground border-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:opacity-60 transition",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "px-6 py-3 max-h-[48px] text-base",
        xs: "rounded-xl px-3 py-2 text-xs",
        sm: "rounded-xl px-3 py-1",
        md: "rounded-xl py-2 px-3",
        lg: "py-5 px-3",
        icon: "h-8 w-8 rounded-xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, loading, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const theme = React.useContext(ThemeContext);

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), {
          "cursor-pointer": props?.disabled,
        })}
        ref={ref}
        disabled={loading || props?.disabled}
        {...props}
      >
        {loading && (
          <Loader
            className="mr-2"
            size={10}
            color={
              variant?.includes("outline")
                ? theme?.mutedColor
                : theme?.primaryForegroundColor
            }
          />
        )}
        {size === "icon" && loading ? null : props.children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export const RevertToPOSButton = ({ before, after, onRevert }) => {
  const [visible, setVisible] = React.useState(false);
  const { t } = useTStream("common");

  const beforeRevert = React.useCallback(() => {
    setVisible(false);
    onRevert();
    toast.success(t("Reverted. Save to apply."));
  }, [onRevert]);

  if (!before || before === after) return <></>;
  return (
    <DrawerDialogStacked
      open={visible}
      trigger={
        <Button variant="outline" size="icon" className="border-accent">
          <RefreshCwIcon className="h-4 w-4 text-muted" />
        </Button>
      }
      onOpenChange={setVisible}
      title={t("Revert")}
      description={t("Would you like to revert this item to the POS?")}
      icon={
        <div className="h-full w-full flex items-center relative justify-center">
          <div className="absolute bg-primary w-full h-full opacity-10" />
          <RefreshCwIcon className="h-6 w-6 text-primary" />
        </div>
      }
    >
      <div className="flex items-center space-x-3 w-full justify-around">
        <div className="flex relative flex-col w-full items-center">
          <p className="text-sm absolute left-[2.5rem] top-[-0.8rem] border-2 border-border bg-background px-2 rounded-xl text-muted">
            {t("Before")}
          </p>
          <div className="p-2 border w-[80%] rounded-xl bg-background border-2">
            <p className="text-center">{before || "No description"}</p>
          </div>
        </div>
        <ChevronRightIcon className="w-12 h-12 text-muted mt-3" />
        <div className="flex relative flex-col w-full items-center">
          <p className="text-sm absolute left-[2.5rem] top-[-0.8rem] border-2 border-border bg-background px-2 rounded-xl text-muted">
            {t("After")}
          </p>
          <div className="p-2 border w-[80%] rounded-xl bg-background border-2">
            <p className="text-center">{after || "No description"}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center w-full space-x-3 mt-4">
        <Button
          variant="destructive-outline"
          className="w-full"
          onClick={() => setVisible(false)}
        >
          {t("Cancel")}
        </Button>
        <Button className="w-full" onClick={beforeRevert}>
          {t("Revert")}
        </Button>
      </div>
    </DrawerDialogStacked>
  );
};

export { Button, buttonVariants };
